<template>
  <div>
    <Breadcrumb name1="任务管理" name2="任务添加" />
    <!--    卡片视图-->
    <el-card>
      <el-row>
        <el-col :span="18" :offset="3">
          <el-form
            :model="addForm"
            :rules="addFormRules"
            ref="addFormRef"
            label-width="100px"
          >
          <el-form-item label="任务等级">
              <el-radio-group v-model="addForm.level" :fill="addForm.level == 2 ? isUrgent:'#409EFF'">
                <el-radio-button label="1">正常</el-radio-button>
                <el-radio-button label="2">紧急</el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="任务标题" prop="name">
              <el-input v-model="addForm.name" placeholder="请输入任务标题"></el-input>
            </el-form-item>
            <!-- 富文本编辑器组件 Two-way Data-Binding -->
              <el-form-item label="任务说明" prop="description">
              <quill-editor
                ref="myQuillEditor"
                v-model="addForm.description"
                class="editor"
              />
              </el-form-item>
              <el-form-item label="任务附件">
                <el-upload
                  class="upload-demo"
                  :action="uploadURL"
                  :on-preview="handlePreview"
                  :on-remove="handleRemove"
                  :headers="headersObj"
                  :on-success="handleSuccess"
                  :data="attachmentFilesType"
                  :file-list="fileList"
                  multiple
                  drag
                >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                <div class="el-upload__tip" slot="tip">只能上传pdf,doc,docx文件，且不超过50M</div>
                </el-upload>
              </el-form-item>
              <el-form-item label="起止日期">
              <el-date-picker
                v-model="pickerDate"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="周期类型">
              <el-radio-group v-model="taskRepeatType">
                <el-radio label="1">单次</el-radio>
                <el-radio label="3">每周</el-radio>
                <el-radio label="4">每月</el-radio>
              </el-radio-group>
              <el-alert v-if="taskRepeatType *1 === 1"
                title="单次：任务在开始至结束只需完成一个任务。建议发布一个月内需要完成的任务。"
                type="success">
              </el-alert>
               <el-alert v-else-if="taskRepeatType *1 === 3"
                title="每周：任务在开始至结束时间内系统会自动生成每周一条相同任务，完成单位需要每周操作。建议发布时间不超过3个月"
                type="warning">
              </el-alert>
              <el-alert v-else-if="taskRepeatType *1 === 4"
                title="每月：任务在开始至结束时间内系统会自动生成每月一条相同任务，完成单位需要每月操作。建议发布时长不超过12个月"
                type="warning">
              </el-alert>
            </el-form-item>
            <el-form-item label="周几完成" v-show="taskRepeatType * 1==3">
              <el-radio-group v-model="weekValue">
                <el-radio-button label="1">周一</el-radio-button>
                <el-radio-button label="2">周二</el-radio-button>
                <el-radio-button label="3">周三</el-radio-button>
                <el-radio-button label="4">周四</el-radio-button>
                <el-radio-button label="5">周五</el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="几日完成" v-show="taskRepeatType * 1==4">
              <el-select v-model="monthValue" placeholder="请选择">
                <el-option
                  v-for="item in monthData"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="任务类型/完成条件">
              <el-radio-group v-model="doneType">
                <el-radio label="1">通知</el-radio>
                <el-radio label="2">图片</el-radio>
                <el-radio label="3">媒体</el-radio>
              </el-radio-group>
              <el-alert v-if="doneType *1 === 1"
                title="通知：任务对象无需要上传资料，可查看任务详情，收到短信提醒。操作阅读确认后即完成任务。"
                type="warning">
              </el-alert>
              <el-alert v-else-if="doneType *1 === 2"
                title="图片：任务对象只需/只能上传图片，提交审核后完成任务。"
                type="success">
              </el-alert>
              <el-alert v-else
                title="媒体：任务对象要上传资料，收到短信提醒。上传资料格式丰富，可支持20个文件。"
                type="success">
              </el-alert>
            </el-form-item>
            <el-form-item label="任务对象">
              <el-transfer
                class="transfer"
                filterable
                :filter-method="filterMethod"
                filter-placeholder="请输入单位名称"
                v-model="departmentSelectValue"
                :titles="['所有单位', '参与单位']"
                :data="departmentListdata">
              </el-transfer>
            </el-form-item>
                  <!--            添加按钮-->
            <el-form-item>
            <el-button type="primary" v-if="!isEdit" class="AddBtn" @click="addTask"
              >发布任务</el-button>
            <el-button type="primary" v-if="isEdit" class="AddBtn" @click="addTask"
            >确定修改</el-button>
            </el-form-item>
        </el-form>
      </el-col>
      </el-row>
    </el-card>
    <!-- 发布确认框 -->
    <el-dialog
      title="任务发布确认"
      :visible.sync="centerDialogVisible"
      width="50%"
      center>
      <div class="taskTitle"><el-tag v-if="addForm.level * 1 === 1">正常</el-tag><el-tag v-if="addForm.level * 1 === 2" type="danger">紧急</el-tag><span>{{ addForm.name }}</span></div>
      <div v-html="addForm.description" class="contentArea"></div>
      <div class="repeatTime">任务周期：{{ addForm.start_date}}-{{ addForm.start_time}} 至 {{ addForm.end_date}}-{{ addForm.end_time}}</div>
      <div class="">参与单位：共{{departmentSelectName.length}}个</div>
      <div class="tag-group">
        <el-tag
          v-for="item in departmentSelectName"
          :key="item.key"
          type="success">
          {{item.label}}
      </el-tag>
      </div>
      <div>任务类型：<el-tag v-if="doneType * 1 === 1">仅通知</el-tag>
      <el-tag v-if="doneType * 1 === 2">上传图片</el-tag>
      <el-tag v-if="doneType * 1 === 3">多媒体</el-tag>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">重新编辑</el-button>
        <el-button type="primary" @click="handleAddTask" v-if="!isEdit">确定发布</el-button>
        <el-button type="primary" @click="handleEditTask" v-else>确定修改</el-button>
      </span>
  </el-dialog>
  </div>
</template>

<script>
import _ from 'lodash'
import { goodsAddFormRulesMixin } from '@/common/mixin.js'
import Breadcrumb from 'components/content/breadcrumb/Breadcrumb'
export default {
  name: 'Add',
  components: {
    Breadcrumb
  },
  mixins: [goodsAddFormRulesMixin],
  data() {
    const generateMonthData = _ => {
      const data = []
      for (let i = 1; i <= 31; i++) {
        data.push({
          value: i,
          label: `每月${i}日`
        })
      }
      return data
    }
    return {
      fileList: [],
      datePickerDefault: [],
      isEdit: false,
      centerDialogVisible: false,
      betweenDays: 0,
      betweenHours: 0,
      pickerDate: null,
      // 任务周期
      taskRepeatType: '1',
      // 紧急任务的样式
      isUrgent: '#F56C6C',
      // 上传文件的额外参数
      attachmentFilesType: {
        type: 2
      },
      monthData: generateMonthData(),
      monthValue: 1,
      weekValue: 1,
      // 完成类型
      doneType: '3',
      departmentListdata: [],
      departmentSelectValue: [],
      departmentSelectName: [],
      filterMethod(query, item) {
        return item.label.indexOf(query) > -1
      },
      activeIndex: '0',
      taskParticipant: [],
      // 添加商品的表单数据对象
      addForm: {
        name: '', // 我的名字
        description: '', // 任务描述
        attachments: {
          images: [], // 图片附件
          files: [] // 文件附件
        }, // 任务附件
        department_ids: [1], // 参与单位
        project_id: 1, // 所属项目
        level: 1, // 任务等级
        items: [3], // 完成条件1仅通知2：多图3：多媒体
        type: 1, // 任务周期：1:单次2:每天3:每周4:每月
        start_date: '2023-02-07', // 开始日期
        end_date: '2023-02-07', // 结束日期
        start_time: '08:00', // 开始时间
        end_time: '23:59', // 结束时间
        special_day: 7 // 周期指定日期：每周1-7每月1-31
      },
      // 上传图片的URL地址
      uploadURL: 'https://todo.1tomall.com/api/upload/attachment',
      // 图片上传组件的headers请求头对象
      headersObj: {
        Authorization: 'Bearer ' + window.sessionStorage.getItem('token')
      },
      departmentList: [],
      // 图片预览的对话框
      previewDialogVisible: false,
      // 表单验证规则
      addFormRules: {
        name: [
          { required: true, message: '请输入任务名称', trigger: 'blur' },
          { min: 3, max: 100, message: '长度在 3 到 100 个字符', trigger: 'blur' }
        ],
        description: [
          { required: true, message: '请输入任务详细', trigger: 'blur' }
        ],
        pickerDate: [
          { type: 'date', required: true, message: '请选择日期', trigger: 'change' }
        ],
        departmentSelectValue: [
          { required: true, message: '请选择任务对象', trigger: 'blur' }
        ]
      }
    }
  },
  created() {
    this.getDepartmentList()
    if (this.$route.query.id) {
      this.getTaskDetail(this.$route.query.id)
      this.isEdit = true
    }
  },
  methods: {
    // 编辑任务
    async handleEditTask() {
      const formObj = _.cloneDeep(this.addForm)
      if (formObj.attachments.files !== null && formObj.attachments.files !== undefined) {
        formObj.attachments.files.map(function(obj) {
          obj.file_name = obj.name // 分配新键
          delete obj.name // 删除旧键
          return obj
        })
      }
      const { data: res } = await this.$http.post('project/task/edit', formObj)
      if (res.code !== 200) {
        return this.$message.error('编辑失败请重试')
      }
      this.$message.success('任务发布成功!')
      this.centerDialogVisible = false
      this.$router.push('/welcome')
    },
    // 获取任务详情
    async getTaskDetail(val) {
      const taskDetailQsObj = {
        id: val
      }
      const { data: res } = await this.$http.post('project/task/detail', taskDetailQsObj)
      if (res.code !== 200) {
        return this.$message.error('详情获取失败!请刷新重试')
      }
      console.log(res.data)
      // 获取任务计划的开始时间及结束时间
      this.datePickerDefault[0] = res.data.task_job.start_date + ' ' + res.data.task_job.start_time
      this.datePickerDefault[1] = res.data.task_job.end_date + ' ' + res.data.task_job.end_time
      console.log(this.datePickerDefault[0])
      this.addForm = res.data
      // 绑定日期、周期类型、完成条件、任务对象
      this.pickerDate = this.datePickerDefault
      this.doneType = res.data.items[0] + ''
      this.taskRepeatType = res.data.task_job.type + ''
      if (res.data.task_job.type !== 2) {
        this.monthValue = res.data.task_job.special_day
      } else {
        this.weekValue = res.data.task_job.special_day
      }
      this.departmentSelectValue = res.data.department_ids
      if (res.data.attachments.files !== null && res.data.attachments.files !== undefined) {
        this.fileList = res.data.attachments.files.map(function(obj) {
          obj.name = obj.file_name ? obj.file_name : obj.name // 分配新键
          delete obj.file_name // 删除旧键
          return obj
        })
      }
      console.log(this.fileList)
      // 更改按钮显示
    },
    // 获取部门列表
    async getDepartmentList() {
      const { data: res } = await this.$http.post('department/list', { page: 1, pageSize: 60 })
      if (res.code !== 200) {
        return this.$message.error('单位列表获取失败!')
      }
      this.departmentList = res.data
      // 给穿梭框赋值
      this.departmentList.forEach((departmentObj, index) => {
        this.departmentListdata.push({
          label: departmentObj.id + '-' + departmentObj.name,
          key: departmentObj.id
        })
      })
      // console.log(this.data)
    },
    // 附件删除
    handleRemove(file) {
      const removeFileName = file.response.data.url
      const filesArray = this.addForm.attachments.files
      this.addForm.attachments.files.splice(filesArray.findIndex(item => item.url === removeFileName), 1)
      // console.log(this.taskDoQuery.attachments.files)
    },
    // 附件传输成功
    handleSuccess(response) {
      if (response.code === 200) {
        const fileInfo = {
          id: response.data.id,
          ext: response.data.ext,
          // 文档名称
          file_name: response.data.file_name,
          mime_type: response.data.mime_type,
          url: response.data.url
        }
        // this.taskDoQuery.attachments.files = []
        this.addForm.attachments.files.push(fileInfo)
        console.log(this.taskDoQuery)
      }
    },
    handlePreview() {},
    // 转化日期
    dateFormat(time, type) {
      const dt = new Date(time)
      const y = dt.getFullYear()
      const m = (dt.getMonth() + 1 + '').padStart(2, '0')
      const d = (dt.getDate() + '').padStart(2, '0')
      const hh = (dt.getHours() + '').padStart(2, '0')
      const mm = (dt.getMinutes() + '').padStart(2, '0')
      const ss = (dt.getSeconds() + '').padStart(2, '0')
      // yyyy-mm-dd hh:mm:ss
      if (type === 'hours') {
        return `${hh}:${mm}`
      } else if (type === 'days') {
        return `${y}-${m}-${d}`
      } else {
        return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
      }
    },
    // 处理提交添加动作
    async handleAddTask() {
      const loading = this.$loading({
        lock: true,
        text: '任务发布中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      const form = _.cloneDeep(this.addForm)
      console.log(form)
      const { data: res } = await this.$http.post('project/task/create', form)
      console.log(res)
      if (res.code !== 200) {
        loading.close()
        return this.$message.error('添加任务失败!')
      } else if (res.code === 400) {
        loading.close()
        return this.$message.error(res.message)
      }
      loading.close()
      // this.$message.success('任务发布成功!')
      this.centerDialogVisible = false
      this.$router.push('/welcome')
    },
    addTask() {
      this.$refs.addFormRef.validate(async valid => {
        if (!valid) {
          return this.$message.error('请填写必要的表单项')
        }
        const dataNow = new Date()
        this.addForm.department_ids = this.departmentSelectValue
        if (this.pickerDate !== null && this.pickerDate !== undefined) {
          this.addForm.start_date = this.dateFormat(this.pickerDate[0], 'days')
          this.addForm.end_date = this.dateFormat(this.pickerDate[1], 'days')
          this.addForm.start_time = this.dateFormat(this.pickerDate[0], 'hours')
          this.addForm.end_time = this.dateFormat(this.pickerDate[1], 'hours')
          this.betweenDays = Math.floor((this.pickerDate[1] - this.pickerDate[0]) / 1000 / 3600 / 24)
          this.betweenHours = Math.floor((this.pickerDate[1] - this.pickerDate[0]) / 1000 / 3600)
          if (this.taskRepeatType === '3' && this.betweenDays < 7) {
            return this.$message.error('周任务周期须在7天以上，请重新规划日期')
          } else if (this.taskRepeatType === '4' && this.betweenDays <= 28) {
            return this.$message.error('周任务周期须在30天以上，请重新规划日期')
          }
          if (this.betweenHours < 12) {
            return this.$message.error('任务截止时间至少大于12小时')
          }
        } else {
          return this.$message.error('请选择日期')
        }
        if (this.addForm.department_ids.length < 1) {
          return this.$message.error('至少要添加一个任务对象')
        }
        if (this.taskRepeatType * 1 === 3) {
          this.addForm.special_day = this.weekValue * 1
        } else if (this.taskRepeatType * 1 === 4) {
          this.addForm.special_day = this.monthValue * 1
        } else {
          this.addForm.special_day = 1
        }
        this.addForm.type = this.taskRepeatType * 1
        this.addForm.items[0] = this.doneType * 1
        // 赋值给预览的单位
        this.departmentSelectName = this.departmentListdata.filter((item) => {
          return this.departmentSelectValue.some(i => item.key === i)
        })
        this.centerDialogVisible = true
        // 执行添加的业务逻辑
        // 不能直接做转换因为在 级联选择器 中双向绑定了 goods_cat 作为key
        // this.addForm.goods_cat = this.addForm.goods_cat.join(',')
      })
    }
  },
  computed: {
  }
}
</script>

<style lang="less" scope>
.AddBtn {
  margin-top: 15px;
}
.el-form-item{ line-height:100%;}
.ql-editor{ height:100px; min-height: 100px !important;}
.ql-container{
  .ql-snow{
    border-color:#DCDFE6 !important
  }
}
.editor{ line-height: 100%;}
.ql-toolbar .ql-snow{border-color:#DCDFE6 !important}
.transfer .el-input{width:calc(100% - 30px)}

.transfer .el-transfer-panel__list{min-height: 300px;}
.transfer .el-transfer-panel__body{ min-height: 370px;}
.transfer .el-transfer-panel .el-transfer-panel__header .el-checkbox__label{font-size: 14px; color: #606266;}
.viewDepartmentList li{ float: left; margin-right: 10px; margin-bottom:10px;}
.viewDepartmentList{ overflow: hidden; padding-bottom:20px;}
.tag-group{padding-top:20px; padding-bottom:10px;}
.tag-group .el-tag{ margin-right:10px; margin-bottom: 10px;}
.taskTitle{ font-weight: bold; font-size: 24px; line-height:175%; display: flex; align-items: center;}
.repeatTime{ padding-bottom:10px;}
.contentArea{ padding:20px 0;}
</style>
